import React from 'react';
// import sections
import MyHero from '../components/sections/MyHero';

const Opening = () => {

  return (
    <>
      <MyHero className="illustration-section-01" />
    </>
  );
}

export default Opening;