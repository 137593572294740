import React, { useState, useEffect, useReducer } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
import Image from '../elements/Image';
import Modal from '../elements/Modal';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

function secondsToTime(secs){
  let hours = Math.floor(secs / (60 * 60));

  let divisor_for_minutes = secs % (60 * 60);
  let minutes = Math.floor(divisor_for_minutes / 60);

  let divisor_for_seconds = divisor_for_minutes % 60;
  let seconds = Math.ceil(divisor_for_seconds);

  let obj = {
    "h": hours,
    "m": minutes,
    "s": seconds
  };
  return obj;
}

const reducerSeconds = (seconds, action) => {
  console.log("DEBUG : call reducer countDown :");
  switch(action.type){
    case "CountDown":
      let secondsDown = seconds - 1;
      return secondsDown;
default:
      return seconds;
  }
}

const reducerTime = (timeState, action) => {
  console.log("DEBUG : call reducer countDown :");
  switch(action.type){
    case "SetTime":
      return {seconds: timeState.seconds, timer: action.payload};
    case "CountDown":
      let secondsDown = timeState.seconds - 1;
      if(secondsDown<=0){
        clearInterval(timeState.timer);
      }
      return {seconds: secondsDown, timer: timeState.timer};
    default:
      return timeState;
  }
}


const MyHero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const [videoModalActive, setVideomodalactive] = useState(false);

  const openModal = (e) => {
    e.preventDefault();
    setVideomodalactive(true);
  }

  const closeModal = (e) => {
    e.preventDefault();
    setVideomodalactive(false);
  }   

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const canvasWidth = 1200;
  const canvasHeight = 600;
  const itemWidth = 40;
  const itemHeight = 100;
  const [spotLightClassActive, setSpotLightClassActive] = useState(classNames('searchlight'));
  const [debugClick, setDebugClick] = useState('None');
  const [pointX, setPointX] = useState(0);
  const [pointY, setPointY] = useState(0);
  const [clickCount, setClickCount] = useState(0);
  const [score, setScore] = useState(0);
  const [screenWidth, setScreenWidth] = useState(canvasWidth);
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [time, setTime] = useState(0);

  const initialState = {seconds: 10,timer: 0};
  const [timeState, dispatchTime] = useReducer(reducerTime,initialState);

  const initialItemArray = [{id:1,moveX:0,moveY:0}];
  const [itemArray, setItemArray] = useState(initialItemArray);

  const [shake, setShake] = useState(0);

  return (
    // <section
    //   {...props}
    //   className={outerClasses}
    // >
      <div id="canvas1" className="canvas" style={{background: "url('/siren-bg-01.jpg') no-repeat center", 
            /*position: "relative", width:canvasWidth+"px", height:canvasHeight+"px"*/
            /*width:"100%", height:"100%"*/
            }} onMouseDown={spotLight}>
            {itemArray.map(item => (
              <Image
                src={require('./../../assets/images/siren02s.png')}
                style={{position: "absolute", top:item.moveY+"px", left:item.moveX+"px"}}
                alt={"Hero"+item.id}
                onMouseDown={() => hitScore(item.id)}
              />
            ))}
            <div> 
              <div class={"canvas-letter "}>Score :  </div><div class={"canvas-letter "+shake}>{score}</div>
            </div>
        </div>
      // <div>Mouse Click : {pointX}, {pointY} Click Count : {clickCount} Hit Score : {score} Timer : {time.m} {time.s} {timeState.seconds} Item : {itemArray.length}</div>
      // </section>
  );

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

function spotLight(e){
    setClickCount(clickCount+1);
    setSpotLightClassActive(classNames('searchlight.on'));
    const clickVal = 'CLICK!';
    if(debugClick!=clickVal){
        setDebugClick(clickVal);
    }else{
        setDebugClick('None');
    }
    console.log("debug on spotlight.");
    const rect = document.getElementById('canvas1').getBoundingClientRect();
    setPointX(e.clientX - Math.round(rect.left));
    setPointY(e.clientY - Math.round(rect.top));
}

function startGame(e){
    console.log("DEBUG : call startGame");
    console.log("DEBUG : check for window size : width="+windowDimensions.width+" height="+windowDimensions.height);
    const rect = document.getElementById('canvas1').getBoundingClientRect();
    console.log(rect.left+" "+rect.right+" "+rect.width);
    setScreenWidth(Math.round(rect.left));
    startTimer();
}

function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
  }

function hitScore(id){
  console.log("DEBUG : before change value in hit item : "+itemArray[0].moveX+", "+itemArray[0].moveY);
    setScore(score+1);
    setShake("shake");
    setTimeout(() => setShake(""), 300);

    const x = getRandomInt(0,(windowDimensions.width<canvasWidth?windowDimensions.width:canvasWidth)-itemWidth);
    const y = getRandomInt(0,canvasHeight-itemHeight);
    // setMoveX(x);
    // setMoveY(y);
    console.log("Move to : "+x+","+y);
    let itemArrayTmp = [...itemArray];
    console.log("Move to : "+x+","+y);
    var hitItem = itemArrayTmp.find(obj => {
      return obj.id === id
    });
    let hitItemTmp = {...hitItem};
    hitItemTmp.moveX = x;
    hitItemTmp.moveY = y;
    hitItemTmp.id = itemArrayTmp.length+1;
    itemArrayTmp.push(hitItemTmp);

    hitItem.moveX = getRandomInt(0,(windowDimensions.width<canvasWidth?windowDimensions.width:canvasWidth)-itemWidth);
    hitItem.moveY = getRandomInt(0,canvasHeight-itemHeight);

    console.log("DEBUG : after change value in hit item : "+itemArrayTmp[0].moveX+", "+itemArrayTmp[0].moveY);
    console.log("DEBUG : number of array : "+itemArrayTmp.length);
    setItemArray(itemArrayTmp);
}

function startTimer() {
  console.log("DEBUG : call startTimer");
  if (timeState.timer == 0 && timeState.seconds > 0) {
    let intervalID = setInterval(() => {
      dispatchTime({type: "CountDown"});
      }, 1000);
    dispatchTime({type: "SetTime",payload:intervalID});
    console.log("DEBUG : start interval with ID : "+intervalID);
  }
}


}



MyHero.propTypes = propTypes;
MyHero.defaultProps = defaultProps;

export default MyHero;
  