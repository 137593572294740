import React from 'react';
// import sections
import GameCanvas from '../components/sections/GameCanvas';

const Game = () => {

  return (
    <>
      <GameCanvas className="illustration-section-01" />
    </>
  );
}

export default Game;